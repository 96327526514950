@import 'styles/_variables';
@import 'styles/_mixins';

.Root {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, 0.5);
    opacity: 0;
    transition: opacity 220ms;
    pointer-events: none;
    z-index: -999;

    &__isVisible {
        opacity: 1;
        z-index: 99999999;
        pointer-events: auto;
    }
}

.body {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    border-radius: 12px;
    background-color: #fff;
    transition: transform 220ms;
    transform: scale(0);

    &__isVisible {
        transform: scale(1);
    }
}
