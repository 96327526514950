@import 'styles/_variables';
@import 'styles/_mixins';

.Root {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.questions {
    padding: 20px 0 10px;
    max-height: 545px;
    flex: 1;
    overflow-y: auto;
}

.question {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 44px;
    width: 100%;
    padding: 10px 10px 10px 20px;
    color: #fff;
    font-size: 14px;
    font-weight: $font-bold;

    &__isActive {
        color: #000;
        background-color: #fff;
    }

    span {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border-radius: 20px;
        min-width: 20px;
        width: 20px;
        height: 20px;
        font-size: 13px;
        color: green;
    }

    > div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        text-align: left;
        height: 100%;

        img {
            min-width: 24px;
            width: 24px;
            margin-right: 15px;
        }
    }
}

.questionTitle {
    max-height: 32px;
    overflow: hidden;
}

.ActivityIndicator {
    margin: 20px;
}

.summary {
    height: 44px;
    font-size: 16px;
    font-weight: medium;
    color: #fff;

    &:hover {
        text-decoration: underline;
    }
}

.submit {
    height: 50px;
    border-bottom-left-radius: 10px;
    color: #fff;
    font-size: 18px;
    font-weight: medium;
    background-color: $color-brand-blue;
}
