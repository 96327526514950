@import 'styles/_variables';
@import 'styles/_mixins';

.Root {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
}

.image {
    width: 100%;
    height: 250px;
    object-fit: cover;
    background-color: #fff;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

.CanvasDraw {
    width: 100% !important;
    height: 250px !important;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

.buttons {
    display: flex;
    align-items: center;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
}

.clear,
.submit {
    flex: 1;
    height: 40px;
}

.clear {
    background-color: #fff;
    border-bottom-left-radius: inherit;
}

.submit {
    background-color: $color-brand-blue;
    border-bottom-right-radius: inherit;
}
