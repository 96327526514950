@import 'styles/_variables';
@import 'styles/_mixins';

.root {
  display: flex;
  align-items: flex-end;

  > input {
    border: 1px solid rgb(202, 202, 202);
    border-radius: 6px;
    padding: 0 12px;
    font-size: 13px;
    height: 36px;
    max-width: 320px;
    width: 100%;
  }

  // filter by
  > div {
    margin-left: 35px;

    // title
    > span {
      font-size: 12px;
      font-weight: bold;
      color: gray;
    }

    > div {
      height: 36px;
      margin-top: 5px;
    }
  }
}

.SelectLanguage {
  white-space: nowrap;
  padding: 0 10px;
  width: 130px;
  height: 100%;
  border: 1px solid rgb(202, 202, 202);
  border-radius: 6px;
  font-size: 13px;
}

.tags {
  display: flex;
  align-items: center;
  position: relative;
}

.tagsList {
  display: inline-flex;
  align-items: center;
  height: 100%;
  max-width: 200px;
  overflow: hidden;

  // tag
  > div {
    white-space: nowrap;
    color: rgb(100, 100, 100);
    font-size: 15px;
    margin-right: 5px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.tagsDropdownToggle {
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  color: #000;
  width: 40px;
  padding-left: 10px;
  letter-spacing: 2px;
}

.tagsDropdown {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 10;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 1px 1px 3px rgba(#000, 0.2);

  // tag
  > div {
    padding: 2px 8px;
    color: rgb(100, 100, 100);
    font-size: 14px;
    cursor: pointer;

    &:hover {
      background-color: rgba(#000, 0.05);
      text-decoration: underline;
    }
  }
}