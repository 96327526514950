@import 'styles/_variables';
@import 'styles/_mixins';

.Root {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.Input {
    margin: 30px 0 20px;
    height: 40px;
    width: 100%;
    font-size: 18px;
    border-bottom: 1px solid #ccc !important;
    background-color: #fff;
}

.options {
    display: flex;
    flex-direction: column;
    max-height: 200px;
    overflow-y: auto;
}

.option {
    display: flex;
    align-items: center;
}

.removeOption {
    width: 40px;
    height: 40px;
    border-radius: 40px;

    &:hover {
        box-shadow: 1px 1px 5px rgba(#000, 0.1);
    }
}

.InputOption {
    height: 30px;
    width: 100%;
    font-size: 14px;
    border-bottom: 1px solid #ccc !important;
    background-color: #fff;
}

.addOption {
    margin-top: 10px;
    font-size: 15px;
    align-self: flex-start;

    &:hover {
        text-decoration: underline;
    }
}

.footer {
    margin-top: 30px;
}

.removeQuestion,
.changeQuestion {
    margin-right: 10px;
}

.removeQuestion {
    background-color: $color-red;
    color: #fff;
}
