@import 'styles/_variables';
@import 'styles/_mixins';

.Root {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: 50px;
    margin: 0 auto;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 1px 1px 3px rgba(#000, 0.25);
}

.input {
    text-align: center;
    background-color: #fff;
    flex: 1;
    height: 100%;
}

.decrement,
.increment {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 50px;
    font-size: 20px;
    color: #000;
    background-color: #fff;
}

.decrement {
    font-size: 42px;
}
