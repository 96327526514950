@import 'styles/_variables';
@import 'styles/_mixins';

.Root {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 80%;
}

.cover {
    height: 120px;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-bottom: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.options {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
}

.option {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 6px;
    width: 48%;
    height: 130px;
    margin-bottom: 15px;
    overflow: hidden;
    cursor: pointer;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0 0 1px #c4c4c4;
    transition: box-shadow 320ms;

    &:hover {
        box-shadow: $box-shadow-heavy;
    }

    &_title {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: rgba(#000, 0.6);
        color: #fff;
        font-size: 14px;
        padding: 5px;
        padding-left: 10px;
    }
}

.color {
    height: 100%;
    flex: 1;
}

.InputFile {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.footer {
    margin-top: 10px;
}

.back {
    margin-right: 10px;
}
