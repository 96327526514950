@import 'styles/_variables';
@import 'styles/_mixins';

.root {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999999;
  background-color: rgba(#000, 0.3);

  > input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

.zone {
  @include fadeInAnimation(ZoneFadeIn);

  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 90%;
  border: 4px dashed #fff;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 260px;
    height: 120px;
    border-radius: 30px;
    background-color: #fff;
    box-shadow: 1px 1px 3px rgba(#000, 0.4);
    font-size: 18px;
    font-weight: 500;
  }
}