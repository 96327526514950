@import 'styles/_variables';
@import 'styles/_mixins';

.Component {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    height: 100%;
}

.submit {
    position: absolute;
    padding: 10px 15px;
    border-radius: 10px;
    max-width: 80%;
    font-size: 15px;
    font-weight: $font-medium;
    line-height: 1.3;
    cursor: pointer;
    background-color: rgba(#fff, 0.8);
}
