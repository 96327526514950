@import 'styles/_variables';
@import 'styles/_mixins';

.Root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    &:link,
    &:visited {
        color: inherit;
        text-decoration: none;
    }

    img {
        max-width: 40px;
        max-height: 50px;
    }

    span {
        padding: 0 10px;
        font-size: 12px;
        line-height: 1.2;
        margin-top: 6px;
        max-height: 30px;
        overflow: hidden;
        word-wrap: break-word;
        word-break: break-all;
        color: #000;
    }
}
