@import 'styles/_variables';
@import 'styles/_mixins';

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  color: #000;
  margin: 10px 20px;
  padding: 10px 20px;

  // row
  > div {
    display: flex;
    flex: 1;
    border-bottom: 1px solid rgba(#000, 0.1);

    &:last-child {
      border-bottom: 0 none;
    }

    // column
    > div {
      flex: 1;
      padding: 5px 15px;
      border-right: 1px solid rgba(#000, 0.1);

      &:last-child {
        border-right: 0 none;
      }
    }
  }
}

.download {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 45px;
  height: 45px;
  cursor: pointer;
  transition: background-color 220ms;

  &:hover {
    background-color: rgba(#000, 0.05);
  }

  &:hover {
    background-color: rgba(#000, 0.15);
  }
}