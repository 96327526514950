@import 'styles/_variables';
@import 'styles/_mixins';

.SuspenseFallback {
    flex: 1 0;
    height: 24px;
    background-color: $color-brand-primary;
    border-radius: $border-radius-small;
    animation-delay: 0, 1.5s;
    animation-duration: 1.5s, 1s;
    animation-iteration-count: infinite, 1;
    animation-name: loading, init;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;

    @keyframes init {
        0% {
            opacity: 0;
            transform: scale(0);
        }

        100% {
            opacity: 1;
            transform: scale(1);
        }
    }

    @keyframes loading {
        0% {
            background-color: rgba($color-brand-primary, 0.05);
        }

        50% {
            background-color: rgba($color-brand-primary, 0.2);
        }

        100% {
            background-color: rgba($color-brand-primary, 0.05);
        }
    }
}
