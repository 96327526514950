@import 'styles/_variables';
@import 'styles/_mixins';

.Component {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color-brand-blue;

    &__logo {
        max-width: 50%;
        padding: 0 $spacing-third $spacing-double;
    }

    &__ActivityIndicator {
        stroke: $color-orange !important;
    }
}
