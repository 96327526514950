@import 'styles/_variables';
@import 'styles/_mixins';

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > div {
    &:last-child {
      display: flex;
      flex-direction: column;
    }
  }
}

.tabs {
  padding: 20px 0 10px;
  max-height: 470px;
  overflow-y: auto;
}

.tab {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
  width: 100%;
  padding: 0 10px 0 20px;
  font-size: 15px;
  font-weight: $font-bold;

  &__isActive {
    color: $color-brand-blue;
    border-bottom: 1px solid rgba($color-brand-blue, 0.2);
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;

    img {
      width: 24px;
      margin-right: 15px;
    }
  }
}

.questions {
  display: flex;
  flex-direction: column;
}

.discard {
  height: 50px;
  color: #000;
  font-size: 18px;
  opacity: 0.5;
  transition: opacity 220ms, background-color 220ms;

  &:hover {
    opacity: 1;
    background-color: rgba(#000, 0.05);
  }
}

.submit {
  height: 50px;
  border-bottom-left-radius: 10px;
  color: #fff;
  font-size: 18px;
  font-weight: medium;
  background-color: $color-brand-blue;
}
