@import 'styles/_variables';
@import 'styles/_mixins';

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 10%;

  // button
  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-width: 200px;
    border-radius: 10px;
    height: 150px;
    padding: 15px 10px;
    font-size: 17px;
    font-weight: bold;
    box-shadow: 1px 1px 5px rgba(#000, 0.2);
    cursor: pointer;
    transition: box-shadow 220ms;

    // img container
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 80px;
      margin-bottom: 10px;
    }

    &:hover {
      box-shadow: 1px 1px 5px rgba(#000, 0.4);
    }
  }
}

.templates {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.templatesHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  // title
  > span {
    color: $color-brand-blue;
    font-size: 28px;
    font-weight: bold;

    // loading
    > span {
      margin-left: 20px;
    }
  }

  // close
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    transition: background-color 220ms;
    cursor: pointer;

    &:hover {
      background-color: rgba(#000, 0.05);
    }
  }
}

.templateFilters {
  margin-bottom: 25px;
}

.templatesListEmpty {
  font-size: 16px;
  color: gray;
  font-style: italic;
}

.templatesList {
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(3, 31%);
  // margin-bottom: 50px;
  grid-gap: 30px 0;
  // margin: 0 5px;
}