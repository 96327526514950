// stylelint-disable at-rule-no-unknown
// ------------------------------------- //
// mixins for media queries

@mixin mQuery($maxWidth) {
    @media (max-width: $maxWidth) {
        @content;
    }
}

@mixin screen-lg {
    @media (max-width: $media-lg) {
        @content;
    }
}

@mixin screen-md {
    @media (max-width: $media-md) {
        @content;
    }
}

@mixin screen-sm {
    @media (max-width: $media-sm) {
        @content;
    }
}

@mixin screen-xs {
    @media (max-width: $media-xs) {
        @content;
    }
}

// ------------------------------------- //

@mixin fadeInAnimation($animationName) {
  animation-duration: 200ms;
  animation-timing-function: ease-out;
  animation-delay: 100ms;
  animation-iteration-count: 1;
  animation-fill-mode: backwards;
  animation-name: $animationName;

  @keyframes #{$animationName} {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

@mixin screenRoot {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    width: calc(100% - #{$Nav-width-collapsed + ($spacing * 2)});

    @content;
}

@mixin screenBody {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 10px;
    margin: 0 $spacing;

    @content;
}

@mixin screenTitle {
    display: flex;
    align-items: center;
    font-size: 32px;
    height: 80px;
    padding: 0 $spacing;

    @content;
}

@mixin switchButtonContainer {
    display: flex;
    align-items: center;
    padding: 0 32px;
    height: 90px;

    @content;
}

@mixin switchButton {
    margin-right: 12px;
    height: 30px;
    width: 158px;
    color: #4B506D;
    font-size: 15px;
    border-radius: 10px;
    background-color: #F0F0F0;

    &__isActive {
        background-color: $color-brand-blue;
        color: #fff;
    }

    @content;
}

@mixin buttonBrandSolid {
    border-radius: 10px;
    font-size: 16px;
    font-weight: $font-medium;
    color: #fff;
    background-color: $color-brand-blue;

    @content;
}

@mixin arrowBottom ($size) {
    position: absolute;
    bottom: -$size;
    width: 0;
    height: 0;
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid #fff;
    z-index: 2;

    @content;
}

@mixin badgeCount {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: $font-medium;
    color: #fff;
    width: 23px;
    height: 23px;
    border-radius: 23px;
    background-color: $color-red;
    border: 1px solid #fff;

    @content;
}

@mixin screenSwitchButtonContainer {
    flex: 1;
    height: 100%;
    width: calc(100% - #{$Nav-width-collapsed + ($spacing * 2)});

    &__title {
        @include screenTitle;
    }

    &__body {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 10px;
        margin: 0 $spacing;

        &_buttons {
            display: flex;
            align-items: center;
            padding: 0 32px;
            height: 90px;

            &_button {
                @include switchButton;
            }
        }
    }

    @content;
}
