@import 'styles/_variables';
@import 'styles/_mixins';

// .Button {
//
// }

.isProcessing,
.isDisabled {
  pointer-events: none;
}

.isProcessing {
  opacity: 0.6;
}

.isDisabled {
  opacity: 0.5;
}



// index.tsx

.root {
  transition-duration: 320ms;
  transition-property: background-color, opacity, color;
  cursor: pointer;
}