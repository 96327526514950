@import 'styles/_variables';
@import 'styles/_mixins';

.Root {
    display: grid;
    grid-template-columns: repeat(3, 33%);
    grid-gap: 8px;
    width: 100%;
    z-index: 2;
}

.type {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    height: 60px;
    padding-left: 20px;
    font-size: 17px;
    font-weight: $font-medium;
    line-height: 1.2;
    border-radius: 10px;
    box-shadow: 1px 1px 5px rgba(#000, 0.1);

    &:hover {
        box-shadow: $box-shadow-heavy;
    }

    &_icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        margin-right: 15px;
    }
}

.mandatory {
    display: flex;
    align-items: center;
    margin-left: 30px;
}
