@import 'styles/_variables';
@import 'styles/_mixins';

.Root {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.InputFile {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100%;
    font-size: 16px;
    color: #000;
    border: 2px dashed #000;
    background-color: #fff;
    border-radius: 10px;
    opacity: 1 !important;
}

.progress {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.progressText {
    font-size: 18px;
    font-weight: $font-medium;
    margin-bottom: 6px;
}

.progressBar {
    position: relative;
    width: 66%;
    height: 16px;
    border-radius: 16px;
    background-color: lightgray;

    > div {
        transition: width 500ms;
        height: 100%;
        min-width: 16px;
        border-radius: inherit;
        background-color: $color-brand-blue;
    }
}

.files {
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(4, 23%);
    overflow-y: auto;
    max-height: 310px;
    grid-gap: 8px;
    width: 100%;
    margin-top: 30px;
}

.file {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 90px;
    border-radius: 10px;
}

.fileImage {
    display: flex;
    justify-content: center;
    align-items: stretch;
    overflow: hidden;
    height: 100%;
    width: 100%;
    border-radius: inherit;

    img {
        border-radius: inherit;
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
}

.fileGeneric {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    padding: 0 10px;
    max-width: 100%;

    img {
        width: 40px;
        margin-bottom: 5px;
    }
}

.fileName {
    max-width: 100%;
    height: 13px;
    overflow: hidden;
    line-height: 1.2;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-wrap: break-word;
    word-break: break-all;
}

.fileRemove {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    opacity: 0;
    background-color: rgba(#000, 0.7);
    transition: opacity 200ms;
    cursor: pointer;

    &:hover {
        opacity: 1;
    }

    img {
        width: 50px;
    }
}
