@import 'styles/_variables';
@import 'styles/_mixins';

.Root {
    position: relative;
    width: 90%;
    margin: 0 auto;
}

.thumb {
    margin-top: -4px;
    width: 16px;
    height: 16px;
    border-radius: 16px;
    background-color: #fff;
}

.value {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 30px;
    color: #fff;
    margin-top: 10px;
    font-size: 17px;
    font-weight: $font-bold;
}
