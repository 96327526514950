@import 'styles/_variables';
@import 'styles/_mixins';

#root,
#AppRoot {
    display: flex;
    flex: 1;
    min-height: 100%;
    width: 100%;
}
