*,
*::before,
*::after {
    box-sizing: border-box;
}

html,
body {
    margin: 0;
    height: 100%;
    background-color: $background-color-body;
}

body {
    line-height: $font-line-height-default;
    font-size: $font-size-body;
    font-family: $font-family-body;
    font-weight: $font-weight-body;
    color: $font-color-body;
    -moz-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjus: 100%;
}

input {
    border: 0 none;

    &:focus {
        outline: none;
    }
}

h1, h2, h3, h4, h5, h6 { /* stylelint-disable-line */
    margin: 0;
}

img {
    display: block;
}

button {
    // standard config
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-align: center;
    user-select: none;
    transition-timing-function: ease-in-out;
    transition-duration: 320ms;
    transition-property: background-color, color, opacity, border-color, box-shadow;

    // normalize
    -webkit-appearance: none;
    text-transform: none;
    overflow: visible;
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
    padding: 0;
    border-radius: 0;
    border: 0 none;
    background-color: transparent;

    &:focus {
        outline: none;
    }
}

.mapboxgl-marker {
    position: absolute;
    top: 0;
    left: 0;
    will-change: transform;
    opacity: 1;
    transition: opacity 0.2s;
}

.mapboxgl-popup {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    will-change: transform;
    pointer-events: none;
}

.mapboxgl-popup-anchor-top,
.mapboxgl-popup-anchor-top-left,
.mapboxgl-popup-anchor-top-right {
    flex-direction: column;
}

.mapboxgl-popup-anchor-bottom,
.mapboxgl-popup-anchor-bottom-left,
.mapboxgl-popup-anchor-bottom-right {
    flex-direction: column-reverse;
}

.mapboxgl-popup-anchor-left {
    flex-direction: row;
}

.mapboxgl-popup-anchor-right {
    flex-direction: row-reverse;
}

.mapboxgl-popup-tip {
    width: 0;
    height: 0;
    border: 10px solid transparent;
    z-index: 1;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
    align-self: center;
    border-top: none;
    border-bottom-color: #fff;
}

.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip {
    align-self: flex-start;
    border-top: none;
    border-left: none;
    border-bottom-color: #fff;
}

.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
    align-self: flex-end;
    border-top: none;
    border-right: none;
    border-bottom-color: #fff;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
    align-self: center;
    border-bottom: none;
    border-top-color: #fff;
}

.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip {
    align-self: flex-start;
    border-bottom: none;
    border-left: none;
    border-top-color: #fff;
}

.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
    align-self: flex-end;
    border-bottom: none;
    border-right: none;
    border-top-color: #fff;
}

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
    align-self: center;
    border-left: none;
    border-right-color: #fff;
}

.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
    align-self: center;
    border-right: none;
    border-left-color: #fff;
}

.mapboxgl-popup-content {
    position: relative;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(#000, 0.2);
    padding: 7px 12px 6px;
    font-size: 14px;
    font-weight: $font-medium;
    color: #505D6F;
    pointer-events: auto;
}

.mapboxgl-popup-anchor-top-left .mapboxgl-popup-content {
    border-top-left-radius: 0;
}

.mapboxgl-popup-anchor-top-right .mapboxgl-popup-content {
    border-top-right-radius: 0;
}

.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-content {
    border-bottom-left-radius: 0;
}

.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-content {
    border-bottom-right-radius: 0;
}

.mapboxgl-popup-track-pointer {
    display: none;
}

.mapboxgl-popup-track-pointer * {
    pointer-events: none;
    user-select: none;
}

.react-toast-notifications__container {
    z-index: 10000 !important;
}

.react-toast-notifications__toast__content {
    word-wrap: break-word;
    word-break: break-word;
}
