@import 'styles/_variables';
@import 'styles/_mixins';

.Root {
    position: fixed;
    top: 88px;
    left: $Nav-width-collapsed;
    width: 456px;
    height: calc(100% - 88px);

    @include screen-lg {
        width: 380px;
    }
}

.SearchMessages {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    width: 100%;
    height: 100%;
    background-color: #fff;
}
