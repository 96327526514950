@import 'styles/_variables';
@import 'styles/_mixins';

.root {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 50px;
}

.row {
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  > img {
    margin-right: 20px;
  }

  > span {
    font-size: 19px;
    color: #000;
    width: 200px;
  }
}

.SelectLanguage {
  padding: 0 10px;
  height: 50px;
  width: 150px;
  border-radius: 6px;
  font-size: 14px;
  border: 1px solid rgb(235, 235, 235);
}