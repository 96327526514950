@import 'styles/_variables';
@import 'styles/_mixins';

.Root {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.ActivityIndicator {
    position: absolute;
}
