@import 'styles/_variables';
@import 'styles/_mixins';

.Root {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: 50px;
    margin: 0 auto;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 1px 1px 3px rgba(#000, 0.25);
}

.PriceInput {
    text-align: center;
    flex: 1;
}
