//-------------------------------------//
// MEDIA QUERIES
$media-lg: 1200px;
$media-md: 992px;
$media-sm: 768px;
$media-xs: 480px;

//-------------------------------------//
// COLORS
// Projects color palette 
$color-brand-blue: #04162f; // OLD: #2E5BFF
$color-brand-pink: #C800FF;

$color-orange: #FFA400;
$color-green: #24CE85;
$color-red: #FD7366;
$color-yellow: #F7C137;
$color-gray: #707C97;
$color-lightgray: #F6F6F6;
$color-text: #000;

$color-brand-primary: $color-brand-blue;

$color-anchor: $color-text;
$color-body: $color-text;
$background-color-body: $color-lightgray;
$placeholder-color: $color-gray;

//-------------------------------------//
// FONTS
$font-family-roboto: 'Roboto', arial, sans-serif;

$font-size-default: 16px;
$font-line-height-default: 1.5;

$font-light: 300;
$font-normal: 400;
$font-medium: 500;
$font-bold: 700;
$font-bolder: 900;

$font-family-body: $font-family-roboto;
$font-color-body: $color-body;
$font-weight-body: $font-normal;
$font-size-body: $font-size-default;


//-------------------------------------//
// SPACING
$spacing: 20px;
$spacing-half: $spacing / 2;
$spacing-double: $spacing * 2;
$spacing-third: $spacing * 3;

//-------------------------------------//
// Z-INDEXING
// $zIndex-AppHeaderNav: 1000;
// $zIndex-AppSideNav: 1010;
// ...

//-------------------------------------//
// Global Variables

// App/Nav
$Nav-width-collapsed: 66px;

// Radius
$border-radius-small: 4px;
$border-radius-medium: 8px;
$border-radius-large: 10px;

// Box Shadow
$box-shadow-light: 0 0 18px rgba(29, 42, 68, 0.1);
$box-shadow-heavy: 10px 10px 25px rgba(29, 42, 68, 0.1);
$box-shadow-heavy-highlight: 0 5px 15px rgba(46, 91, 255, 0.25);
