@import 'styles/_variables';
@import 'styles/_mixins';

.Root {
    display: grid;
    grid-template-columns: repeat(4, 24%);
    grid-gap: 10px 9px;
    background-color: #fff;
    width: 800px;
    padding: 30px;
    border-radius: 10px;
    overflow-y: auto;
}

.item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 140px;
}

.image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 6px;
}
