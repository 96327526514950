@import 'styles/_variables';
@import 'styles/_mixins';

.Component {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 500;
    background-color: rgba(#000, 0.7);

    &__close {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    &__img,
    &__video {
        position: relative;
        display: block;
        z-index: 2;
        max-width: 85%;
        max-height: 80%;
        border-radius: $border-radius-large;
        background-color: rgba(#fff, 0.2);
    }
}
