@import 'styles/_variables';
@import 'styles/_mixins';

.Root {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9000;
    overflow-y: auto;
    padding-bottom: 50px;
    padding-top: 75px;
    animation-duration: 200ms;
    animation-timing-function: ease-out;
    animation-delay: 100ms;
    animation-iteration-count: 1;
    animation-name: ModalPopup;
    animation-fill-mode: backwards;

    @keyframes ModalPopup {
        0% {
            transform: scale(0);
        }

        100% {
            transform: scale(1);
        }
    }
}

.background {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, 0.3);
}

.body {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    max-width: 85%;
    width: 100%;
    z-index: 15;

    @include screen-md {
        max-width: 100%;
    }
}
