@import 'styles/_variables';
@import 'styles/_mixins';

.Root {
    display: block;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 10px;
    font-size: 16px;
    color: #fff;
    outline: none;
    resize: none;
    background-color: transparent;
    border: 0 none;
    border-bottom: 1px solid rgba(#fff, 0.5);
    transition: border-bottom 120ms;

    &:focus {
        border-bottom: 2px solid rgba(#fff, 1);
    }
}
