@import 'styles/_variables';
@import 'styles/_mixins';

.root {
  position: fixed;
  top: 0;
  right: 0;
  width: 380px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  transition: transform 300ms ease-in-out;
  transform: translateX(380px);
  box-shadow: 0 0 8px rgba(#000, 0.15);
  z-index: 10000;
}

.frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.drag {
  position: absolute;
  top: 0;
  left: -6px;
  width: 12px;
  height: 100%;
  opacity: 0;
  cursor: col-resize;
  background-color: lightgray;

  &:hover {
    opacity: 0.7;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
