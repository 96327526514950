@import 'styles/_variables';
@import 'styles/_mixins';

.Component {
    display: flex;
    flex-direction: column;
    position: fixed;
    background-color: #fff;
    box-shadow: 0 0 8px rgba(#000, 0.15);
    border-radius: 10px;
    padding: 8px 0;
    min-width: 140px;
    z-index: 1;

    &__item {
        text-align: left;
        justify-content: flex-start !important;
        font-size: 14px !important;
        width: 100%;
        min-height: 26px;
        padding: 0 13px !important;

        &:hover {
            background-color: #EAEFFF !important;
        }
    }
}
