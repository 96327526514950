@import 'styles/_variables';
@import 'styles/_mixins';

.Root {
    position: fixed;
    top: 0;
    left: $Nav-width-collapsed;
    width: 456px;
    height: 100%;
    background-color: #fff;
    box-shadow: 0 0 8px rgba(#000, 0.15);
}

.iframe {
    width: 100%;
    height: 100%;
    border: 0 none;
}
