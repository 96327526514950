@import 'styles/_variables';
@import 'styles/_mixins';

.Component {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: #000;

    video {
        width: 100%;
    }
}

.close {
    position: absolute;
    top: 30px;
    left: 5px;
    width: 50px;
    height: 50px;
}

.submit {
    position: absolute;
    bottom: 50px;
    width: 50px;
    height: 50px;
    border: 2px solid #fff;
    border-radius: 50px;
    background-color: rgba(#000, 0.4);
}
