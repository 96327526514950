@import 'styles/_variables';
@import 'styles/_mixins';

.Root {
    display: flex;
    align-items: center;
    height: 40px;
    padding-left: 28px;
    padding-right: 10px;
    font-size: 14px;
    cursor: pointer;
    transition: color 200ms;

    &__isActive {
        color: $color-brand-blue;
        font-weight: $font-medium;
    }

    > div {
        padding-left: 20px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
