@import 'styles/_variables';
@import 'styles/_mixins';

.root {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  height: 150px;
  max-width: 100%;
  background-color: #fff;
  box-shadow: 1px 1px 5px rgba(#000, 0.15);
  transition: box-shadow 320ms;
  cursor: pointer;

  &:hover {
    box-shadow: 1px 1px 5px rgba(#000, 0.4);
  }
}

.body {
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  color: #fff;

  // background image
  > div {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    // darkening overlay
    > div {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(#000, 0.35);
    }
  }

  > strong {
    position: relative;
    padding: 0 15px;
    font-size: 16px;
    line-height: 1.2;
  }

  > span {
    position: absolute;
    top: 6px;
    right: 10px;
    font-size: 12px;
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 32px;
  padding: 0 10px;

  > div {
    // language
    &:first-child {
      display: flex;
      align-items: center;
      font-size: 13px;
      font-weight: bold;

      > img {
        margin-right: 3px;
        max-height: 24px;
      }
    }

    // downloads
    &:last-child {
      font-size: 11px;
      color: gray;
    }
  }
}