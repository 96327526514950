@import 'styles/_variables';
@import 'styles/_mixins';

.Root {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.item {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    font-size: 16px;
    min-height: 50px;
    padding: 5px 15px;
    margin-bottom: 10px;
    line-height: 1.5;
    background-color: #fff;
    border: 1px solid rgba(gray, 0.7);
    border-radius: 6px;
    color: #000;

    &__isActive {
        border-color: $color-brand-blue;
        background-color: $color-brand-blue;
        color: #fff;
    }
}
