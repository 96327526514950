@import 'styles/_variables';
@import 'styles/_mixins';

$coverHeight: 120px;

.Root {
    position: relative;
    display: flex;
    align-items: stretch;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(#000, 0.25);
    background-color: #fff;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @include screen-md {
        width: 100%;
        min-height: 620px;
    }
}

.backgroundOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, 0.4);
}

.Tabs {
    position: relative;
    min-height: 620px;
    max-width: 0;
    min-width: 0;
    opacity: 0;
    z-index: 10;
    border-right: 0 none;
    transition: min-width 200ms, max-width 200ms, opacity 200ms;

    &__isVisible {
        max-width: 200px;
        min-width: 200px;
        opacity: 1;
        border-right: 1px solid #ccc;
    }

    @include screen-md {
        display: none;
    }
}

.body {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    z-index: 10;
    min-width: 980px;
    max-width: 980px;

    @include mQuery(1280px) {
        min-width: 720px;
        max-width: 720px;
    }

    @include screen-md {
        max-width: 100%;
        min-width: 100%;
        width: 100%;
    }
}

.cover {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: $coverHeight;
    width: 100%;
    padding: 20px;
    color: #fff;
    font-size: 26px;
    font-weight: $font-bold;
    margin-bottom: 50px;
}

.error {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: bold;
    border-radius: 10px;
    max-width: 70%;
    padding: 10px 20px;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 1px 1px 3px rgba(#000, 0.5);
}

.buttonClose,
.buttonRestart {
    align-self: center;
    height: 40px;
    width: 100px;
    margin-bottom: 25px;
    border-radius: 10px;
    font-size: 16px;
    color: #fff;
}

.buttonClose {
    background-color: $color-brand-blue;

    @include screen-md {
        display: none;
    }
}

.buttonRestart {
    background-color: $color-orange;
}

.buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 80%;
    margin: 0 auto 20px;
    width: 100%;

    &__centered {
        justify-content: center;
    }

    &__isSuccess {
        @include screen-md {
            justify-content: center;
        }
    }
}

.buttonPrev,
.buttonNext {
    height: 40px;
    padding: 0 12px;
    min-width: 100px;
    border-radius: 10px;
    font-size: 16px;
}

.buttonPrev {
    background-color: $color-lightgray;
    color: #000;
}

.buttonNext {
    background-color: $color-brand-blue;
    color: #fff;
}

.questionTitle {
    color: #fff;
    text-align: center;
    font-size: 20px;
    margin-bottom: 20px;
}

.questions {
    display: flex;
    align-items: center;
    max-width: 80%;
    flex: 1;
}

.description {
    padding: 0 25px;
    font-size: 16px;
    color: #fff;
    word-wrap: break-word;
    word-break: break-word;

    ul {
        padding-left: 20px;
    }

    p {
        margin: 15px 0;
    }
}

.attachments {
    align-self: center;
    display: flex;
    text-align: center;
    max-width: 100%;
    overflow-x: auto;
    margin-bottom: 25px;
    padding-bottom: 15px;
    padding-right: 15px;

    &__isSingle {
        padding-right: 0;

        img,
        video {
            margin-left: 0 !important;
        }
    }

    img,
    video {
        min-width: 400px;
        max-width: 400px;
        height: 100%;
        max-height: 340px;
        border-radius: 10px;
        overflow: hidden;
        margin-left: 15px;
    }

    img {
        object-fit: cover;
    }
}

.questions,
.description {
    width: 100%;
    overflow-y: auto;
    margin: 0 auto 30px;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

.tabsToggle {
    position: absolute;
    left: 10px;
    top: 10px;
    color: white;
    font-size: 13px;

    &:hover {
        text-decoration: underline;
    }

    @include screen-md {
        display: none;
    }
}

.submit {
    padding: 8px 16px;
    border-radius: 5px;
    background-color: #F0F0F0;
    color: #4B506D;

    &__isValid {
        background-color: $color-brand-blue;
        color: #fff;
    }
}
