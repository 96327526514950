@import 'styles/_variables';
@import 'styles/_mixins';

.Component {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: $Nav-width-collapsed;
    min-width: $Nav-width-collapsed;
    height: 100%;
    background-color: $color-brand-blue;
    box-shadow: 4px 0 18px rgba(16, 30, 115, 0.26);
    transition: width 220ms ease-in-out;
    z-index: 10;

    &__version {
        color: #fff;
        text-align: center;
        font-size: 13px;
        font-weight: $font-medium;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: $spacing 0 $spacing;
        cursor: pointer;

        &_Avatar {
            width: 40px;
            height: 40px;
            border-radius: 40px;
        }
    }

    &__items {
        display: flex;
        flex-direction: column;

        &_line {
            height: 1px;
            background-color: rgba(#fff, 0.5);
        }
    }

    &__space {
        flex: 1;
    }

    // &__logout {}
}

.item {
    position: relative;
    width: 100%;
    height: 50px;

    &:hover {
        background-color: rgba(#fff, 0.1);
    }

    &__isActive {
        border-left: 5px solid #fff;
        background-color: #829DFF;
    }

    &__isDisabled {
        opacity: 0.5;
    }

    &_count {
        @include badgeCount {
            position: absolute;
            top: 8px;
            right: 8px;
        }
    }
}

.checkIn {
    width: 25px;
    height: 25px;
    margin-bottom: $spacing-double;
    margin-left: $spacing;
    cursor: pointer;

    &__loader {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.margin {
    width: $Nav-width-collapsed;
    min-width: $Nav-width-collapsed;
}
