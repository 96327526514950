@import 'styles/_variables';
@import 'styles/_mixins';

.Root {
    display: block;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid rgba(gray, 0.5);
    height: 40px;
}
