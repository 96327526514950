@import 'styles/_variables';
@import 'styles/_mixins';

.Root {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.item {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 60px;
    width: 60px;
    margin: 0 15px;
    border-radius: 50px;
    font-size: 30px;
    background-color: #fff;
    border: 1px solid #000;

    &__isActive {
        border-color: $color-brand-blue;
        background-color: $color-brand-blue;
    }
}
