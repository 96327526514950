@import 'styles/_variables';
@import 'styles/_mixins';

.Root {
    width: 100%;
}

.List {
    min-height: 230px;
    max-height: 230px;
    padding: 0 10px;
    border-radius: 10px;
    background-color: #fff;
}
