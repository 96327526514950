@import 'styles/_variables';
@import 'styles/_mixins';

.Root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.invalidQuestions {
    color: red;
    text-align: center;
    max-width: 285px;
    font-size: 15px;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    background-color: #fff;

    &:hover {
        text-decoration: underline;
    }
}

.button {
    width: 200px;
    height: 54px;
    font-size: 18px;
    border-radius: 10px;
    color: #fff;
    background-color: $color-brand-blue;
}

.Player {
    width: 200px;
    height: 200px;
}
