@import 'styles/_variables';
@import 'styles/_mixins';

.Root {
  display: flex;
  align-items: stretch;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(#000, 0.25);
  background-color: #fff;
}

.SelectTemplate {
  padding: 40px 50px;
  min-width: 980px;
  max-width: 980px;
  min-height: 620px;

  @include mQuery(1280px) {
    padding: 20px;
    min-width: 720px;
    max-width: 720px;
  }
}

.Tabs {
  min-height: 620px;
  max-width: 200px;
  min-width: 200px;
  border-right: 1px solid #ccc;
}

.body {
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 30px 0;
  min-width: 980px;
  max-width: 980px;

  @include mQuery(1280px) {
    min-width: 720px;
    max-width: 720px;
  }
}

.submit {
  padding: 8px 16px;
  border-radius: 5px;
  background-color: #F0F0F0;
  color: #4B506D;

  &__isValid {
    background-color: $color-brand-blue;
    color: #fff;
  }
}
