@import 'styles/_variables';
@import 'styles/_mixins';

.Root {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 80%;
}

.input {
    height: 40px;
    width: 100%;
    font-size: 18px;
    border-bottom: 1px solid #ccc;
    background-color: #fff;
}

.submit {
    margin-top: 20px;
}
