@import 'styles/_variables';
@import 'styles/_mixins';

.Root {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 80%;
}

.textarea {
    padding: 10px;
    width: 100%;
    min-height: 140px;
    max-height: 320px;
    border-radius: 10px;
    border-width: 1px;
    resize: vertical;
}

.InputFile {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    border-radius: 6px;
    width: 48%;
    height: 130px;
    margin-bottom: 15px;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0 0 1px #c4c4c4;
    transition: box-shadow 320ms;

    &:hover {
        box-shadow: $box-shadow-heavy;
    }
}

.attachments {
    display: grid;
    grid-template-columns: repeat(2, 48%);
    grid-gap: 10px;
    width: 100%;
}

.attachment {
    width: 100%;
    height: 130px;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 6px;

    &_remove {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: inherit;
        width: 100%;
        height: 100%;
        background-color: rgba(#000, 0.6);
        transition: opacity 320ms;
        opacity: 0;

        &:hover {
            opacity: 1;
        }
    }
}

.submit {
    margin-top: 20px;
}
