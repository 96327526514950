@import 'styles/_variables';
@import 'styles/_mixins';

.Root {
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 6px;
    overflow: hidden;
}

.color {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex: 1;
    height: 290px;
    overflow: hidden;
    cursor: pointer;
}
