@import 'styles/_variables';
@import 'styles/_mixins';

@mixin mobile {
  @include mQuery(980px) {
    @content;
  }
}

.backgroud {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#000, 0.2);
  z-index: 10000;
}

.root {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 75%;
  height: 90%;
  align-self: center;
  margin: 3% auto 0;
  border-radius: 10px;
  overflow-x: hidden;
  color: #fff;
  font-size: 16px;
  background-color: #000;
  z-index: 10010;

  @include mobile {
    width: 100%;
    border-radius: 0;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;
  }

  .ActivityIndicator {
    margin-left: 10px;
  }

  .button {
    width: 50px;
    height: 50px;

    &:hover {
      background-color: rgba(#fff, 0.15);
    }

    &:active {
      background-color: rgba(#fff, 0.25);
    }
  }
}

.body {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-bottom: 10px;
}

.user {
  display: flex;
  align-items: center;
  padding-left: 15px;

  > div {
    &:last-child {
      margin-left: 15px;
      font-size: 18px;
    }
  }
}

.date {
  margin-top: 5px;
  padding-left: 20px;
}

.image {
  position: relative;
  width: 100%;
  margin: 15px 0;

  > img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  .ImageZoom {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;

    &:hover {
      opacity: 1;
    }
  }
}

.place {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  margin-bottom: 5px;

  > div {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    > img {
      margin-left: 10px;
      max-width: 24px;
      max-height: 24px;
    }
  }

  .Map {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 200px;
  }
}

.tags,
.text {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 0 20px;

  // title
  > span {
    margin-bottom: 5px;
  }
}

.tags {
  // list
  > div {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    // list item
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 31px;
      padding: 0 20px;
      border-radius: 8px;
      border: 1px solid #fff;
      margin-right: 10px;
      margin-bottom: 10px;

      &:hover {
        background-color: rgba(#fff, 0.1);
      }
    }
  }
}

.text {
  > div {
    > div {
      padding: 10px 20px;
      border: 1px solid #fff;
      border-radius: 10px;
      border-radius: 8px;
    }
  }
}