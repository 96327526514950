@import 'styles/_variables';
@import 'styles/_mixins';

.Root {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 80%;
}
