@import 'styles/_variables';
@import 'styles/_mixins';

.Component {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 180px;
    flex: 1;

    @include screen-lg {
        font-size: 80px;
    }
}
