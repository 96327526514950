@import 'styles/_variables';
@import 'styles/_mixins';

.Root {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.InputSearch {
    min-height: 40px;
    max-height: 40px;
    margin-bottom: 10px;
}

.unsplash {
    padding-left: 15px;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 10px;
}

.images {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    overflow-y: auto;
    min-height: 335px;
    max-height: 335px;
    padding: 0 5px;
}

.image {
    position: relative;
    width: 49%;
    height: 130px;
    margin-bottom: 5px;
    cursor: pointer;
    border-radius: 10px;
    overflow: hidden;

    &_link {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 4px 8px;
        background-color: rgba(#000, 0.7);
        color: #fff;
        line-height: 1.2;
        font-size: 12px;
        text-decoration: none;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.ActivityIndicator {
    align-self: flex-start;
    margin: 25px;
}
